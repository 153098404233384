@import "swiper/css";
@import "swiper/css/effect-cards";

/* Variables */
$IMG_PATH: "/assets/img/";
$ICN_PATH: "/assets/icons/";

:root {
    --text-color: #2A2F45; //#6E6D7A; 
    --error-color: #FF6B6B;
    --default-radius: 0.375em;
    --double-radius: 0.750em;
    --primary-color: #2A2F45;
    --contrast-color: #C26500;
    --secondary-color: #F6C044;
    --background-color: #F7FAFC;
    --button-text-color: #333333;
    --text-color-on-dark: #F7FAFC;
    --border-color: rgba(0, 0, 5, 0.1);
    --stats-background-color: rgba(249, 250, 251);
    --haizzy: url('/assets/img/haizzy_logo.svg');
    --second-background-color: rgba(42, 47, 69, 0.05);
    --banner_img: url('/assets/img/banner_img/background_x3bfht 1.png');
    --banner_img_2: url('/assets/img/banner_img/background_2_diqtsa 1.png');
    --banner_img: url('/assets/img/banner_img/background_x3bfht 1.png');
    --appointment_img: url('/assets/img/hairdresser/iphone-black.png');
    --notification-background: rgba(255, 255, 255, 0.7);
    --default-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    --default-border: 1px solid rgb(221, 221, 221);
    --brand-font: 'Pacifico', cursive;
}

@media (prefers-color-scheme: dark) {
    :root {
        --img-path: "/assets/img/";
        --text-color: #BEC6D4;
        --error-color: #FF6B6B;
        --primary-color: #F7FAFC;
        --contrast-color: #c26500;
        --secondary-color: #F6C044;
        --background-color: #121212; //#2A2F45;
        --button-text-color: #333333;
        --text-color-on-dark: #F7FAFC;
        --stats-background-color: rgba(26, 32, 44);
        --border-color: rgba(255, 255, 245, 0.1);
        --haizzy: url('/assets/img/haizzy_logo_light.svg');
        --second-background-color: rgba(247, 250, 252, 0.05);
        --appointment_img: url('/assets/img/hairdresser/iphone-white.png');
        --notification-background: rgba(0, 0, 0, 0.7);
    }

    img:not(.thumb),
    canvas {
        opacity: .85;
    }
}

*,
::before,
::after {
    margin-top: 0;
    padding-top: 0;
    position: relative;
    box-sizing: border-box;
}

::selection {
    background-size: initial;
    background-clip: initial;
    background-image: initial;
    background-origin: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-color: rgba(246, 192, 68, 0.15);
}

html {
    -webkit-text-size-adjust: 100%;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    display: block;
    max-width: 100%;
    font-size: 62.5%;
    min-width: 20rem;
    scroll-behavior: smooth;

    .layout.extend-body {
        margin-top: -3rem;
    }

    app-footer {
        width: 100%;
    }
}

input::placeholder,
textarea::placeholder,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    white-space: pre;
    word-wrap: normal;
    overflow-x: hidden;
    overflow-y: hidden;
    line-height: 2.5625rem;
}

::placeholder {
    -webkit-text-security: none;
    pointer-events: none;
    color: darkgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ol-attribution,
.ol-rotate {
    display: none;
}

.ol-zoom button {
    padding-bottom: 0;
    color: var(--primary-color);
    border: var(--default-border);
    background-color: var(--notification-background);
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--secondary-color);
}

.swiper-3d .swiper-slide-shadow {
    background-color: transparent;
}

.swiper-slide {
    height: auto;
}

ul.img-list {
    columns: 3;
    column-gap: 16px;

    @media (max-width: 1200px) {
        columns: 3;
    }

    @media (max-width: 992px) {
        columns: 2;
    }

    li {
        list-style: none;
        display: inline-block;
        margin-bottom: 16px;
        position: relative;

        p {
            font-size: 1.6rem;
            line-height: 1 !important;
        }

        img {
            border-radius: 1rem;
            width: 100%;
            object-fit: cover;
        }
    }
}

.custom-select {
    width: 100%;
    margin: auto;
    display: inline-block;

    select {
        width: 100%;
        border: none;
        font-size: inherit;
        padding: .5em 2em .5em 1em;
        border-radius: var(--default-radius);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;


    }

    &::after {
        top: 0;
        bottom: 0;
        right: .5em;
        border-left: .7rem solid transparent;
        border-right: .7rem solid transparent;
        border-top: .7rem solid var(--primary-color);
        height: 10px;
        margin: auto;
        position: absolute;
        content: "";
    }

    &:not(.on-ligth) {
        select {
            height: 5.7rem;
            font-weight: 700;
            color: var(--primary-color);
            background-color: var(--second-background-color);
        }

        &::after {
            border-top: .7rem solid var(--primary-color);
        }

    }

    &.on-ligth {
        margin: 0;

        select {
            height: 100%;
            color: var(--button-text-color);
        }

        &::after {
            border-top: .7rem solid var(--button-text-color);
        }
    }
}

@media (max-width: 1023px) {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block;
    }
}

@media (min-width: 1024px) {
    .mobile {
        display: none !important;
    }
}


body {
    font-size: 1.6rem;
    line-height: 1.25;

    &.no_scroll {
        overflow: hidden;
    }

    padding-top: 8rem;
    font-display: optional;
    font-family: 'Poppins',
    sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background-color: var(--background-color);

    &.show_smart_app_banner {
        padding-top: 15.5rem;
    }

    app-faq {
        width: 100%;
    }

    span.brand {
        font-size: inherit;
        font-family: var(--brand-font);
    }

    a.brand span.brand {
        color: var(--primary-color);
    }

    a.btn,
    button {
        border: 0;
        font: inherit;
        color: inherit;
        cursor: pointer;
        background: none;
        overflow: hidden;
        line-height: inherit;
        vertical-align: inherit;

        &[class*='btn'] {
            padding: 1.6rem;
            font-weight: 700;
            text-align: center;
            border-radius: 1rem;
        }

        &[class$='btn--primary'] {
            color: #FFFFFF !important;
            background-color: var(--button-text-color);
            padding-left: 8rem;
            padding-right: 8rem;
        }

        &[class$='btn--secondary'] {
            color: var(--button-text-color);
            background-color: var(--secondary-color);
            padding-left: 8rem;
            padding-right: 8rem;
        }

        &[class$='btn--cancel'] {
            color: var(--text-color);
            background-color: var(--border-color);
        }

        &[class$='btn--outline'] {
            color: var(--primary-color);
            border: 0.0625rem solid var(--primary-color);

            &:hover {
                opacity: 0.8;
            }
        }

        &[class$='btn--icn'] {
            span {
                height: 2.4rem;
                vertical-align: top;
                margin-right: 0.8rem;
                display: inline-block;
            }

            font-weight: 500;
        }

        &.btn.danger {
            padding-left: 0;
            color: var(--error-color);
        }

        &.load[data-title] {
            background-color: var(--secondary-color);

            &::after {
                left: 0;
                top: 0;
                bottom: 0;
                content: '';
                right: 0;
                padding: 1rem;
                overflow: hidden;
                position: absolute;
                white-space: nowrap;
                text-overflow: clip;
                line-height: 1.25rem;
                /*content: attr(data-title);*/
                background: rgba(0, 0, 0, .2);
                -webkit-animation: load ease-out .37s;
                -moz-animation: load ease-out .37s;
                -o-animation: load ease-out .37s;
                -ms-animation: load ease-out .37s;
                animation: load ease-out .37s;
            }
        }

        &.disable,
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: var(--border-color);
        }
    }

    button.plus-icon {
        height: 2.5rem;
        outline: none;
        font-size: 1.5rem;
        margin: 0.3125rem 0 0 0;
        transition: transform .3s ease-out;
        transform: rotate(0);
        transform-origin: center center;

        #circle-plus #icon {
            fill: var(--primary-color);
        }
    }

    img {
        max-width: 100%;
    }

    h1 {
        font-size: 4.3rem;
        line-height: 5rem;
        color: var(--primary-color);

        strong {
            color: var(--secondary-color);
        }
    }

    .h2 {
        font-weight: 700;
    }

    h2,
    .h2 {
        font-size: 3.3rem;
        color: var(--primary-color);

        @media (max-width: 64em) {
            font-size: 2.4rem;
        }
    }

    h3 {
        font-size: 2.25rem;
        color: var(--primary-color);

        @media (max-width: 64em) {
            font-size: 1.6rem;
        }
    }

    h4 {
        font-size: 2rem;
        color: var(--primary-color);
    }

    .h5,
    h5 {
        font-size: 1.6rem;
        margin-top: 0.5rem;
        color: var(--primary-color);
    }

    h6 {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        color: var(--primary-color);

        &.subheading {
            width: 100%;
            line-height: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    label {
        font-size: 1.6rem;
        line-height: 1.55;
        color: var(--text-color);

        &:not(.lbl_checkbox) {
            font-weight: 500;
        }
    }

    p {
        color: var(--text-color);
        font-size: 1.6rem;
        line-height: 1.55;
        font-weight: 400;

        a,
        a:active,
        a:visited {
            color: var(--contrast-color);
            display: initial;

            &.with--contrast {
                color: #383838;
                text-decoration: underline;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &.error {
            color: var(--error-color);
        }
    }

    .app-only {
        display: none;
    }

    i.footnote {
        color: var(--text-color);
        font-size: 1.75rem;
        line-height: 1.55;
        font-weight: 400;

        a {
            text-decoration: underline;
        }
    }

    a,
    a:active,
    a:visited {
        color: inherit;
        text-decoration: none;
        display: inline-block;

        .link__text-to-hide {
            position: absolute;
            clip: rect(1px, 1px, 1px, 1px);
            -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
            clip-path: inset(0px 0px 99.9% 99.9%);
            overflow: hidden;
            height: 1px;
            width: 1px;
            padding: 0;
            border: 0;
            top: 50%;
        }
    }

    sup {
        font-weight: bold;
    }

    ul:not(.default) {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    picture {
        padding: 0;
        margin: 0;
    }

    svg {
        .primary-color {
            fill: var(--primary-color);
        }
    }

    .svg__drawing {
        svg {
            width: 100%;
            max-height: 30.75rem;
            transition: opacity 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);

            @media (prefers-color-scheme: dark) {
                opacity: .85;
            }

            .background-color {
                fill: var(--background-color);
            }
        }
    }

    .concept__content {
        .swiper-container {
            .swiper-wrapper {
                // width: 80vw;
                align-items: center;
            }

            @media (min-width: 768px) {
                .swiper-pagination {
                    opacity: 0;
                }
            }

            @media (max-width: 767px) {
                .swiper-pagination-bullet-active {
                    background: var(--button-text-color);
                }
            }

            .swiper-slide {
                .img__container {
                    width: 100%;

                    img {
                        margin-left: 2rem;
                        max-width: 25rem;
                    }
                }
            }
        }
    }

    .tips_video {
        width: 100%;
        overflow: hidden;
        border-radius: var(--default-radius);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--default-radius);
        -webkit-transition: opacity .25s cubic-bezier(.16, 1.08, .38, .98);
        -o-transition: opacity .25s cubic-bezier(.16, 1.08, .38, .98);
        transition: opacity .25s cubic-bezier(.16, 1.08, .38, .98);

        &.section_cover {
            height: 35rem;
        }

        &.product_cover {
            object-fit: contain;
            width: auto;
            height: 200px;
        }

        &.to__top {
            object-position: top;
        }
    }


    [class$='text--heading'] span {
        color: var(--secondary-color);
    }

    [class$='text--container'] {
        @media (max-width: 40em) {
            padding: 0 1.6rem;
        }
    }

    .buttons__group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .modal-wrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        padding: 0;
        // padding: 1vw 0;
        overflow: auto;
        position: fixed;

        .overlay {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            pointer-events: auto;
            -webkit-backdrop-filter: blur(1em);
            -moz-backdrop-filter: blur(1em);
            backdrop-filter: blur(1em);
            background-color: rgba(88, 102, 118, .6)
        }
    }

    .modal-details {
        width: 100%;
        line-height: normal;

        section {
            margin-top: 0;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            &:not(.no-padding) {
                @media (min-width: 1023px) {
                    max-width: 60rem;
                    margin: auto;
                }
            }
        }

        .modal-details_content {
            display: flex;
            padding: 0 1.6rem 4rem;

            .mobile {
                width: 100%;
                text-align: left;
            }

            width: 100%;
            margin: auto;
            flex-direction: column;

            &_header {

                display: flex;
                flex-direction: row;
                margin-bottom: 1.6rem;
                align-items: flex-start;
                justify-content: space-between;

                h5 {
                    width: 80%;
                    margin: 0;
                    line-height: normal;
                }

                button.close-icon {
                    padding: 0;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    background-color: var(--border-color);

                    svg {
                        fill: var(--text-color);
                    }

                }
            }
        }
    }

    .modal_sub_header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icn_back {
            line-height: 0;
            cursor: pointer;
        }

        h2,
        .h2,
        h3 {
            margin-left: 1.6rem;
            margin-bottom: 0;
        }
    }

    app-quality-icons {
        width: 100%;
    }

    app-confetti {
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        position: absolute;
        min-height: 100vh;
    }

    .top__banner,
    .bottom__banner {
        padding-bottom: 3rem;
        background-color: rgba(88, 102, 118, .1);
    }

    .layout {
        display: flex;
        margin: 0 auto;
        padding-top: 0;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        justify-content: center;
        overflow-x: hidden;
        overflow-y: hidden;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;

        @media (min-width: 90.625rem) {
            width: 66.666667%;
        }

        @media (min-width: 64rem) {
            width: 91.666667%;
        }

        &:not(.no-padding) {
            @media (max-width: 63.9375rem) {
                max-width: 50rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }

        &.full-width {
            width: 100%;
            max-width: 100%;
        }

        .wrap {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            margin-top: 10rem;
            max-width: 77.5rem;

            @media (max-width: 64rem) {
                width: 100%;
            }

            h1 {
                font-size: 3.5rem;
                font-weight: 600;
                margin-top: 2.5rem;
                line-height: 5rem;
                margin-bottom: 1.25rem;
            }

            .content-summary {
                padding: 1em;
                margin-bottom: 2em;
                border-radius: var(--default-radius);
                background-color: var(--second-background-color);

                ul {
                    margin: 0 !important;

                    li {
                        list-style: none;
                        white-space: nowrap;

                        &.content-summary_sub_one {
                            padding-left: 1.0em;
                        }

                        &.content-summary_sub_two {
                            padding-left: 1.8em;
                        }

                        a {
                            color: var(--contrast-color);
                            text-decoration: underline;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100%;
                        }
                    }
                }
            }

            h2,
            .h2 {
                width: 100%;
                font-weight: 600;
                font-size: 2.75rem;
                margin-bottom: 3rem;
            }

            .wrap-DIY {

                h2,
                .h2 {
                    margin-bottom: 0;
                }

                ul {
                    padding-left: 4rem;
                }
            }

            p {
                margin-bottom: 1.6rem;
                line-height: 3rem;

                &.baseline {
                    opacity: 0.7;
                    font-weight: 700;
                }
            }

            ol {
                list-style-type: decimal;
            }

            ul {
                list-style-type: disc;
            }

            ol,
            ul:not(.social_links) {
                line-height: 2rem;
                margin-left: 0.75rem;
                margin-bottom: 2.5rem;
                list-style-position: outside;

                li {
                    line-height: 3rem;
                    font-size: 1.6rem;
                    padding-left: 0.75rem;
                    // margin-bottom: 1.6rem;
                    color: var(--text-color);

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .row__hair-type {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 2rem;

                .img__hair-type {
                    width: 100px;
                    margin-right: 2rem;

                    img {
                        object-fit: contain;
                    }

                    span {
                        right: 0;
                        width: 40px;
                        height: 40px;
                        bottom: -10px;
                        display: block;
                        text-align: center;
                        border-radius: 50%;
                        position: absolute;
                        line-height: 35px;
                        border: 2px solid var(--background-color);
                        color: var(--button-text-color);
                        background-color: var(--secondary-color);
                    }
                }
            }

            .list__hair-type {
                li {
                    list-style-type: none;

                    &::before {
                        content: attr(name);
                        position: absolute;
                        font-family: var(--brand-font);
                    }

                    p {
                        max-width: 580px;
                        padding-left: 2.3rem;
                    }
                }
            }
        }

        .article__text--heading.hide {
            position: absolute;
            opacity: 0;
        }

        .article__product {
            display: flex;
            padding: 1em;
            margin-bottom: 2em;
            border-radius: var(--default-radius);
            background-color: var(--second-background-color);

            * {
                margin-bottom: 0 !important;
            }


            .article__product--details--brand {
                color: var(--text-color);
                opacity: 0.7;
            }
        }

        &.related-articles .wrap {
            width: 100%
        }
    }

    .news__form {
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;

        h3 {
            margin: .75rem;
        }

        .input__radio__container {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin: 1rem auto 9rem auto;
            justify-content: space-evenly;

            @media (max-width: 26.75rem) {
                margin-bottom: 4rem;
            }

            .input__radio__container-wrap {
                width: 48%;
                height: 14rem;

                &:first-child {
                    background: url($IMG_PATH + 'undraw_taking_selfie_lbo7.svg') no-repeat center;
                    background-size: contain;
                }

                &:last-child {
                    background: url($IMG_PATH +  'undraw_businesswoman_pc12.svg') no-repeat center;
                    background-size: contain;
                }

                input {
                    opacity: 0;
                    position: absolute;

                    +label {
                        width: 100%;
                        display: block;
                        cursor: pointer;
                        line-height: 31rem;
                        height: calc(100% + 3rem);
                    }

                    &:checked+label {
                        border-radius: 0.625rem;
                        color: var(--secondary-color);
                        border: 0.0625rem solid var(--secondary-color);
                    }
                }

            }

            @media (max-width: 26.75rem) {
                flex-direction: column;

                .input__radio__container-wrap {
                    width: 100%;
                    height: 10rem;

                    &:first-of-type {
                        margin-bottom: 4rem;
                    }

                    input {
                        +label {
                            height: calc(100% + 2.5rem);
                            line-height: 22rem;
                        }
                    }
                }
            }
        }

        .buttons-group {
            width: 100%;
            display: flex;
            margin-top: 2rem;
            flex-direction: row;
            justify-content: space-evenly;

            @media (max-width: 26.75rem) {
                flex-direction: column-reverse;

                button {
                    margin-bottom: 1rem;
                }
            }

            button {
                padding: 2rem;
            }
        }
    }

    .message__container {
        width: 28rem;
        padding: 0.95rem;
        position: absolute;
        border-radius: 0.9375rem;

        @media (min-width: 64rem) {
            top: -3rem;
            left: -20%;
        }

        @media (max-width: 63.9375rem) {
            bottom: -6rem;
            left: 0;
            right: 0;
            margin: auto;
        }

        color: var(--primary-color);
        background: var(--notification-background);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
        -webkit-backdrop-filter: saturate(130%) blur(1.25rem);
        backdrop-filter: saturate(130%) blur(1.25rem);
        -webkit-transition: 0.6s var(--ease-out-cubic);
        transition: 0.6s var(--ease-out-cubic);

        p {
            margin: 0;
            color: inherit;
            font-size: 14px;
            z-index: 2;
        }

        .message__container--header {
            margin-bottom: 0.75rem;
        }

        .message__container--header,
        .message__container--header-logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            p {
                opacity: .7;
            }

            .message__container--header-logo {
                span.logo {
                    width: 1.25rem;
                    height: 21px;
                    margin-right: 6px;
                    display: inline-block;
                    background-image: url('/assets/img/h_logo.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
            }
        }
    }

    .form__container {
        form+span {
            color: var(--primary-color);
            font-size: 1.6rem;
            margin-top: 1rem;
            max-width: 43rem;
            display: block;
        }
    }

    form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        align-items: stretch;

        @media (min-width: 1024px) {
            align-items: flex-start;
            -webkit-box-pack: start;
        }

        @media (min-width: 640px) {
            flex-direction: row;

            &.align__center {
                justify-content: center;
            }
        }

        .form__input--wrapper {
            width: 100%;

            >.form__input {
                display: inline-block;
                vertical-align: top;
                width: calc(50% - 8px);

                &:nth-child(1) {
                    margin-right: 16px;
                }
            }
        }

        .form__input {
            &.error .form__input--container {
                border-color: var(--error-color);
                background-color: rgba(255, 107, 107, .2);
            }

            &.error label {
                color: var(--error-color);
            }

            label {
                margin-bottom: 0.4rem;
                margin-top: 1.2rem;
                display: block;
            }

            .form__input--container {
                @media (max-width: 639px) {
                    margin-bottom: 0.75rem;
                }

                @media (min-width: 640px) {
                    margin-right: 0.5rem;
                }

                @media (min-width: 1024px) {
                    margin-bottom: 0px;
                    width: 100%;
                }

                &:not(.full-width) {

                    @media (min-width: 1280px) {
                        width: calc(100% - 185px);
                    }
                }

                @media (max-width: 1279px) and (min-width: 1024px) {
                    margin-bottom: 0.5rem;
                }

                opacity: .8;

                display: flex;
                max-width: 100%;
                border-radius: 0.6rem;
                border: 1px solid var(--border-color);
                background-color: #FFFFFF;

                &.multiline {
                    height: 150px;
                }

                &:not(.multiline) {
                    height: 53px;
                }


                input,
                textarea {
                    width: 100%;
                    height: 100%;
                    border: none;
                    resize: none;
                    background: none;
                    font-size: 1.6rem;
                    border-radius: 0.6rem;
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;

                    &:focus {
                        outline: none !important;
                        border: 1px solid var(--secondary-color);
                    }
                }

                +p {
                    font-size: 1.2rem;
                }
            }
        }

        .banner__link--btn {
            @media (min-width: 1024px) {
                width: 100%;
            }

            @media (min-width: 1280px) {
                width: 160px;
            }
        }
    }

    main {
        width: 100%;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;

        .container {
            @media (min-width: 1450px) {
                width: 66.666667%;
            }

            @media (min-width: 1024px) {
                width: 91.666667%;
            }

            @media (max-width: 1023px) and (min-width: 640px) {
                max-width: 50rem;
            }

            width: 100%;
            margin: auto;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
        }

        section {
            &:not(.no-padding) {

                @media (min-width: 1450px) {
                    width: 66.666667%;
                }

                @media (min-width: 1280px) {
                    padding: 10rem 0;
                }

                @media (min-width: 1024px) {
                    flex-direction: row;
                    margin-left: 5rem;
                    margin-right: 5rem;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                }

                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }

            width: 100%;
            display: flex;
            max-width: 100vw;
            margin-top: 3rem;
            align-items: center;
            flex-direction: column;
            -webkit-box-align: center;

            &.reversable {
                @media (max-width: 1023px) {
                    flex-direction: column-reverse;
                }
            }

            .img__container {
                margin: auto;

                @media (min-width: 1024px) {
                    width: 50%;
                    padding-left: 2.5rem;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }

                width: 100%;
                margin-top: 3rem;
                margin-bottom: 3rem;
                max-width: 600px;

                &.app_mockup {
                    &.img__container {
                        @media (max-width: 639px) {
                            margin: 0;
                        }
                    }
                }
            }

            .bloc_container {
                @media (min-width: 1024px) {
                    align-items: flex-start;
                    width: 50%;
                }

                &:not(.on__cover) {
                    @media (min-width: 45rem) {
                        padding: 0 4rem;
                    }
                }

                width: 100%;
                display: flex;

                align-items: center;
                margin-bottom: 1.5rem;
                flex-direction: column;
                -webkit-box-align: center;

                h2,
                .h2 {
                    @media (min-width: 1024px) {
                        width: 75%;
                    }

                    width: 100%;
                    font-weight: 700;
                    margin-bottom: 2.5rem;
                    letter-spacing: -0.025em;
                }

                p {
                    @media (min-width: 1024px) {
                        width: 90%;
                    }

                    width: 100%;
                    margin-bottom: 2.5rem;
                }
            }
        }

        ul.gallery {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(6, 5vw);
            grid-gap: 15px;

            li {
                width: 100%;
                height: 100%;
                object-fit: cover;
                list-style: none;

                picture {
                    height: 100%;
                    display: block;
                    object-position: top;
                }

                &:nth-of-type(1) {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row-start: 1;
                    grid-row-end: 6;
                }

                &:nth-of-type(2) {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 1;
                    grid-row-end: 4;
                }

                &:nth-of-type(3) {
                    grid-column-start: 5;
                    grid-column-end: 9;
                    grid-row-start: 4;
                    grid-row-end: 6;
                }
            }
        }
    }

    .setting-list,
    .personnal_data-list {

        li {
            padding: 2rem 0;
            border-bottom: 1px solid var(--border-color);

            &:not(.disable) {
                cursor: pointer;

                &:hover {
                    background-color: var(--second-background-color);
                }
            }

        }

        li,
        li a {
            width: 100%;

            >* {
                display: inline-block;
                vertical-align: top;
            }

            p {
                margin-left: 1.6rem;
                margin-bottom: 0;

                span {
                    display: block;
                    font-size: 80%;
                }
            }
        }

    }

    .banner__content {
        &::before {
            top: 0;
            bottom: 0;
            content: "";
            width: 100%;
            margin: auto;
            position: absolute;
            background-repeat: no-repeat;
            background-position: 100% 0%;
            background-size: contain;
            background-image: var(--banner_img);

            @media (min-width: 1479px) {
                right: -5rem;
                height: calc(100% - 10rem);
            }

            @media (min-width: 1024px) and (max-width: 1478px) {
                right: -3rem;
                background-image: var(--banner_img_2);
            }

            @media (min-width: 640px) and (max-width: 1023px) {
                height: 100%;
                background-position: 50% 80%;
            }

            @media (min-width: 0px) and (max-width: 639px) {
                height: 100%;
                width: calc(100% - 3.2rem);
                background-position: 50% 95%;
            }
        }

        section {
            p {
                color: var(--text-color);
                max-width: 95%;
            }

            .banner_content__form {
                @media (min-width: 1024px) {
                    width: 50%;
                    padding-right: 8rem;
                }

                display: flex;
                flex-direction: column;
                -webkit-box-align: flex-start;
                align-items: flex-start;
                -webkit-box-pack: center;
                justify-content: center;
            }
        }
    }

    main .concept_content {
        width: 100vw;
        display: flex;
        margin: 2rem 0;
        font-size: 1.25rem;
        align-items: center;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        justify-content: center;
        background-color: var(--secondary-color);

        section {
            width: 100%;
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;

            @media (max-width: 1023px) and (min-width: 640px) {
                max-width: 50rem;
            }

            h2,
            .h2 {
                @media (min-width: 1024px) {
                    padding-top: 10rem;
                }

                padding-top: 6rem;
            }

            h2,
            .h2,
            p {
                @media (min-width: 1024px) {
                    width: 58.333333%;
                }

                @media (min-width: 1450px) {
                    width: 41.666667%;
                }

                width: 100%;
                font-weight: 700;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding-bottom: 2.5rem;
                color: var(--button-text-color);
            }

            p:last-child {
                @media (min-width: 1024px) {
                    padding-bottom: 10rem;
                }

                padding-bottom: 6rem;
            }
        }
    }

    .top__banner {
        .layout .container {
            @media (min-width: 1024px) {
                flex-direction: row;
                margin-left: 5rem;
                margin-right: 5rem;
                justify-content: space-between;
            }

            @media (min-width: 1280px) {
                padding: 10rem 0;
            }

            @media (min-width: 1450px) {
                width: 66.666667%;
            }
        }
    }

    .images_grid {
        .placeholder {

            button {
                margin: auto;
                display: block;
            }
        }

        .container {
            display: grid;
            grid-gap: 0.8rem;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: masonry;

            .post-grid {
                cursor: pointer;

                img {
                    border-radius: 0.6rem;
                    aspect-ratio: 1;
                }
            }
        }

    }

    .transition-container {
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }

    .main-wrapper {
        width: 66.666667%;

        @media (max-width: 1024px) {
            width: 100%;
        }

        margin: 63px auto;

        .main-wrapper_grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
            grid-gap: 1.5em;

            .main-wrapper_grid__card {
                margin: 0 auto;
                cursor: pointer;
                max-width: 37.1rem;

                a.target_article {
                    width: 100%;
                    height: 100%;
                    display: block;
                    overflow: hidden;
                    transition: all .15s cubic-bezier(0, .21, .69, .71);
                }

                &.blog--card {
                    .card_banner {
                        width: 100%;
                        height: 270px;
                        overflow: hidden;
                        border-radius: 8px;

                        img {
                            height: 100%;
                        }
                    }

                    .card_footer {
                        padding: 1em 0;

                        .card_tags-container {
                            margin: 1em 0;

                            li {
                                font-weight: 700;
                                font-size: 0.75em;
                                padding: .5em 1em;
                                display: inline-block;
                                text-transform: uppercase;
                                color: var(--secondary-color);
                                border-radius: var(--default-radius);
                                background-color: var(--second-background-color);
                            }
                        }

                        h3 {

                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                        }

                        .publish {
                            color: var(--primary-color);
                            margin-bottom: 0.5em;
                            display: block;
                            font-size: 90%;
                            opacity: .8;

                        }

                        .link {
                            color: var(--contrast-color);
                        }

                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 5;
                        }
                    }
                }
            }
        }
    }

    .simulation__container {
        width: 100%;
        padding-top: 10rem;

        .simulation__container--top,
        .simulation__container--footer {
            margin: auto;
            padding: 0 1.5rem;

            @media (min-width: 45rem) {
                padding: 0 5.1rem;
            }

            @media (max-width: 418px) {
                br {
                    display: none;
                }
            }

            @media (max-width: 1023px) and (min-width: 640px) {
                max-width: 50rem;
            }

            @media (min-width: 1024px) {
                width: 91.666667%;
                padding: 0 9rem;
            }

            @media (min-width: 1450px) {
                width: 66.666667%;
            }
        }

        .simulation__container--footer {
            p {
                width: 100%;
                text-align: center;
                font-size: 1.6rem;

                @media (max-width: 64em) {
                    font-size: 1.6rem;
                }
            }
        }

        section {
            padding-top: 0;

            @media (max-width: 1449px) {
                width: 100%;
            }
        }
    }

    .without__question {
        .section-faq {
            display: none;
        }
    }

    @keyframes load {
        0% {
            right: 100%;
        }

        100% {
            right: 0;
        }
    }

    @-moz-keyframes load {
        0% {
            right: 100%;
        }

        100% {
            right: 0;
        }
    }

    @-webkit-keyframes load {
        0% {
            right: 100%;
        }

        100% {
            right: 0;
        }
    }

    @-o-keyframes load {
        0% {
            right: 100%;
        }

        100% {
            right: 0;
        }
    }

    @-ms-keyframes load {
        0% {
            right: 100%;
        }

        100% {
            right: 0;
        }
    }

}